import React from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import Img from "gatsby-image"
// import Markdown from "../components/Markdown"
import * as PropTypes from "prop-types"

import style from "./index.module.css";

const propTypes = {
  data: PropTypes.object.isRequired,
}

class Home extends React.Component {
  render() {
    const content = this.props.data.page
    return (
      <div>
        <Helmet>
          <title>{content.title}</title>
          {content.metaDescription &&
            <meta name="description" content={content.metaDescription} />
          }
        </Helmet>
        {/*
        <section className={style.content}>
          {content.title &&
            <h1 className={style.title}>{content.title}</h1>
          }
          {content.body &&
            <Markdown className={style.body} source={content.body.body} />
          }
        </section>
        */}
        <section className={style.projectList}>
          {content.werk && content.werk.map(project => {
            return (
              <article className={style.project} key={project.id}>
                <Link to={`/werk/${project.slug}/`}>
                  <div className={style.projectContent}>
                    <div className={style.projectSubtitle}>{project.subtitle}</div>
                    <h1 className={style.projectName}>{project.name}</h1>
                  </div>
                  <Img className={style.image} sizes={project.imageOverview.sizes} />
                </Link>
              </article>
            )
          })}
        </section>
      </div>
    )
  }
}

Home.propTypes = propTypes

export default Home

export const homepageQuery = graphql`
  query homepageQuery {
    page: contentfulPageHome {
      title
      metaDescription
      body {body}
      werk {
        id
        name
        slug
        subtitle
        imageOverview {
          sizes(maxWidth: 3500) {
            sizes
            src
            srcSet
            srcWebp
            srcSetWebp
          }
        }
      }
    }
  }
`
